$(document).ready(function () {

    $(".navigate").on('click', function (event) {

        if (this.hash !== "") {

            $('.open-section a').removeClass('active');
            $(this).addClass('active');

            event.preventDefault();

            var toPosition = $(this.hash).offset().top

            $('main').animate({
                scrollTop: $('main').scrollTop() + toPosition - 60
            }, 800);
        }

        // Remove mobile menu
        $('header').removeClass('active');
    });

    // Nav trigger
    $('#navTrigger').on('click', function () {
        $('header').toggleClass('active');
    });

    // on scroll
    $('main').on('scroll', function () {
        var scroll = $('main').scrollTop();
        //        console.log($('#p1').offset().top);
    });


    var scrollDown = $('#scrolldown');
    var documentHeight = $(document).height();
    var headerHeight = document.getElementById("header").scrollHeight;

    //console.log(headerHeight + ' ' + documentHeight);

    if (headerHeight > documentHeight) {
        scrollDown.addClass('show');
    } else {
        scrollDown.removeClass('show');
    }

    // Set cookie
    var mode = getCookie('mode');

    if (mode == 'dark') {
        $('body').addClass('dark');
    } else {
        $('body').removeClass('dark');
    }

    // Change Mode
    $('.modes a').on('click', function (e) {

        e.preventDefault();

        var target = $(this).attr('data-target');

        if (target == 'tume') {
            $('body').addClass('dark');
            document.cookie = "mode=dark";
        } else {
            $('body').removeClass('dark');
            document.cookie = "mode=light";
        }
    });

});


function getCookie(name) {
    function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : null;
}